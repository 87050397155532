import React from "react";
import Seo from "../components/seo"
import Layout, {BlackLink, SegmentLink, StyledLink} from "../components/layout";
import {BlackLine, DottedLine} from "../components/line";

import {Dropdown} from "../components/dropdown";
import countries from '../../static/countries.json';
import {CompactButton} from "../components/button";

const SelectShipment = ({data}) => {
    const [country, setCountry] = React.useState(countries[0]);

    return (
        <div>
            <Seo title="結帳"/>
            <div id="checkout_html"/>
            <div
                style={{
                    display: "flex",
                    maxWidth: 800,
                    margin: '0px auto',
                    justifyContent: 'center',
                    alignItems: 'start',
                    flexDirection: 'column',
                }}
            >
                {/*{*/}
                {/*    Object.values(cart?.items ?? []).map((e) => <div key={e.product.id} style={{*/}
                {/*        // margin: '1rem',*/}
                {/*        display: 'flex',*/}
                {/*        flexDirection: 'row',*/}
                {/*        alignItems: 'center',*/}
                {/*    }}>*/}
                {/*        <div>*/}

                {/*            <img*/}
                {/*                style={{*/}
                {/*                    width: 80,*/}
                {/*                    height: 80,*/}
                {/*                }}*/}
                {/*                src={e.product.coverUrl}/>*/}
                {/*        </div>*/}
                {/*        <div*/}
                {/*            style={{*/}
                {/*                width: 24,*/}
                {/*            }}*/}
                {/*        >*/}

                {/*        </div>*/}
                {/*        <div*/}
                {/*            style={{*/}
                {/*                display: 'flex',*/}
                {/*                flexDirection: 'column',*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <div style={{*/}
                {/*                fontWeight: 'bold'*/}
                {/*            }}>*/}

                {/*                {e.product.name}*/}
                {/*            </div>*/}
                {/*            <div>*/}
                {/*                {*/}
                {/*                    `${e.count} x \$${e.product.price}`*/}
                {/*                }*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div*/}
                {/*            style={{*/}
                {/*                flexGrow: 1,*/}
                {/*            }}*/}
                {/*        >*/}

                {/*        </div>*/}
                {/*    </div>)*/}
                {/*}*/}
                <div style={{width: "100%", height: 20}}/>
                <BlackLine/>
                <div style={{width: "100%", height: 20}}/>
                <div
                    style={{
                        display: "flex",
                    }}
                >

                    <div style={{
                        flexGrow: 1,
                    }}>
                        小計
                    </div>
                    <div>
                        {/*${totalPrice}*/}
                    </div>
                </div>
                <div style={{width: "100%", height: 20}}/>
                <BlackLine/>
                <div style={{width: "100%", height: 20}}/>
                <div
                    style={{
                        fontWeight: "bold",
                    }}
                >
                    運送區域
                </div>
                <div
                    style={{
                        flexGrow: 3,
                        marginTop: 8,
                    }}
                >
                    <Dropdown
                        value={country.code}
                        options={countries.map((e) => ({
                            value: e.code,
                            label: e.cn,
                        }))
                        }
                        onChange={(e) => {
                            const selected = countries.find((s) => s.code === e) ?? countries[0];
                            setCountry(selected);
                        }}
                    />
                </div>
                <div style={{width: "100%", height: 20}}/>
                <div style={{display: 'flex'}}>
                    <CompactButton
                        onClick={async () => {
                            // const r = await client.post('/order/ecpay_cvs_select');
                            document.write(r.data);
                        }}
                    >
                    下一步
                    </CompactButton>
                </div>
            </div>
        </div>
    );
};

export default SelectShipment;
